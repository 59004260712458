'use client'

import { init, tag } from '@blotoutio/edgetag-sdk-js';
import useUser from 'components/hooks/use-user';
import { usePathname } from 'next/navigation';
import { useEffect } from 'react';
import { FunnelData } from 'shared-react-components';

export default function useBlotout({ funnelData, onReady }: { funnelData?: FunnelData, onReady?: () => void }) {
    const edgeUrl = process.env.NEXT_PUBLIC_BLOTOUT_URL;
    const pathname = usePathname();
    const { user, loading: userLoading } = useUser();
    const ready = !userLoading;

  useEffect(() => {
    if (ready) {
    init({
      edgeURL: edgeUrl ?? '',
      disableConsentCheck: true
    });
    tag('PageView');
      setTimeout(() => {
        onReady && onReady();
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, ready])

  return null;
}
