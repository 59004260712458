'use client'

import { Product } from 'lib/bigcommerce/types';
import { Customer } from 'lib/bigcommerce/types/customer';
import { CUSTOM_ANALYTICS_URL } from 'lib/constants';
import * as deviceDetect from 'react-device-detect';
import { CustomAnalytics, FunnelData } from 'shared-react-components';

const getCaseInsensitiveParams = () => {
  return new URLSearchParams(
    Array.from(new URLSearchParams(window.location.search), ([key, value]) => [key.toLowerCase(), value])
  );
}

const baseData = () => {
  if (typeof window === 'undefined') return {};

  const device = getDevice();

  const baseData = {
    device: {
      name: device,
      manufacturer: deviceDetect.isMobile ? deviceDetect.mobileVendor : deviceDetect.browserName,
      type: deviceDetect.isMobile ? deviceDetect.deviceType : 'desktop',
      resolution: {
        width: window.screen.availWidth,
        height: window.screen.availHeight
      },
      viewport: {
        width: window.innerWidth,
        height: window.innerHeight
      },
      encoding: 'UTF-8',
      language: window.navigator.language,
      colors: `${window.screen.colorDepth}-bit`
    },
    browser: {
      manufacturer: device,
      name: deviceDetect.browserName,
      majorVersion: deviceDetect.browserVersion,
      version: deviceDetect.fullBrowserVersion,
      window: {
        width: window.screen.availWidth,
        height: window.screen.availHeight
      }
    },
    os: {
      majorVersion: deviceDetect.osName,
      name: deviceDetect.osName,
      version: deviceDetect.osVersion
    },
    scrollPosition: window.scrollY,
  };
  return baseData;
};

type PageViewProps = {
  customer?: Customer;
  sessionId?: string;
  funnelData?: FunnelData;
};
export const pageEnter = async (props: PageViewProps) => {
  if (typeof window === 'undefined') return {};

  const { customer, sessionId, funnelData } = props;
  const queryParams = getCaseInsensitiveParams();

  const customAnalytics = new CustomAnalytics(CUSTOM_ANALYTICS_URL);
  const pageEnterEvent = {
    ...(sessionId ? { sessionId } : {}),
    test: {
      testId: undefined,
      variantId: undefined,
      testIdVariantId: undefined,
      testName: undefined,
      variantName: undefined
    },
    page: {
      url: {
        raw: window.location.href,
        base: window.location.origin + window.location.pathname,
        queryParams: window.location.search
      },
    },
    ...baseData(),
    referrer: window.document.referrer,
    affiliate: {
      id: queryParams.get('afid'),
      campaignId: queryParams.get('aff_sub') ?? queryParams.get('source')
    },
    platform: {
      id: !!funnelData?.productLine ? 'funnel' : 'storefront',
      funnelData: {
        brand: funnelData?.brand,
        productLine: funnelData?.productLine,
        funnelName: funnelData?.funnelName,
        stepName: funnelData?.stepName,
        type: funnelData?.type,
      }
    },
    ...(customer ? {
      customer: {
        id: customer?.entityId,
        email: customer?.email,
        firstName: customer?.firstName,
        lastName: customer?.lastName,
        phone: customer?.phone
      }
    } : {}),
  };
  return await customAnalytics.pageEnter(pageEnterEvent);
};

export const pageLeave = async (props: PageViewProps) => {
  if (typeof window === 'undefined') return {};

  const customAnalytics = new CustomAnalytics(CUSTOM_ANALYTICS_URL);
  const pageLeaveEvent = baseData();
  return await customAnalytics.pageLeave(pageLeaveEvent);
};

type Order = {
  baseAmount: number;
  billingAddress: {
    firstName: string;
    lastName: string;
    address1: string;
    city: string;
    stateOrProvince: string;
    postalCode: string;
    countryCode: string;
    email: string;
    phone: string;
  };
  coupons: Array<{
    code: string;
  }>;
  consignments: {
    shipping: {
      firstName: string;
      lastName: string;
      address1: string;
      city: string;
      stateOrProvince: string;
      postalCode: string;
      countryCode: string;
      email: string;
      phone: string;
    }
  };
  currency: {
    code: string
  }
  customerId: number;
  discountAmount: number;
  lineItems: {
    physicalItems: OrderLineItem[];
  };
  orderAmount: number;
  orderId: number;
  shippingCostTotal: number;
  taxTotal: number;
};

type PurchaseProps = {
  customer?: Customer;
  sessionId?: string;
  order: Order;
  product?: Product;
  funnelData?: FunnelData;
};

export const purchase = async (props: PurchaseProps) => {
  if (typeof window === 'undefined') return {};

  const { customer, order, product, funnelData } = props;

  const customAnalytics = new CustomAnalytics(CUSTOM_ANALYTICS_URL);
  const queryParams = getCaseInsensitiveParams();
  const couponCode = order.coupons.at(0)?.code;
  const lineItem = order.lineItems?.physicalItems?.at(0);
  const variant = product?.variants?.find(v => lineItem?.sku === v.sku);
  const gpm = variant?.productMetafields?.find(m => m.key === 'gpm')?.value?.toString() ?? product?.productMetafields?.find(m => m.key === 'gpm')?.value?.toString();
  const gpmPrice = tryParseMoney(gpm);
  const quantity = lineItem?.quantity ?? 1;
  const gpmTotal = gpmPrice ? (gpmPrice * quantity) : undefined;

  return await customAnalytics.purchase({
    scrollPosition: window.scrollY,
    affiliate: {
      id: queryParams.get('afid'),
      campaignId: queryParams.get('aff_sub') ?? queryParams.get('source')
    },
    platform: {
      id: !!funnelData?.productLine ? 'funnel' : 'storefront',
      funnelData: {
        brand: funnelData?.brand,
        productLine: funnelData?.productLine,
        funnelName: funnelData?.funnelName,
        stepName: funnelData?.stepName,
        type: funnelData?.type,
      }
    },
    order: {
      id: order.orderId,
      currency: order.currency?.code ?? 'USD',
      channel_id: 'funnel',
      subtotal: order.baseAmount,
      grandTotal: order.orderAmount,
      shipping: order.shippingCostTotal,
      tax: order.taxTotal,
      promoCode: couponCode,
      gpmTotal: gpmTotal,
      items: [customOrderLineItem({ lineItem, product })],
    },
    customer: {
      id: customer?.entityId,
      email: customer?.email,
      firstName: customer?.firstName,
      lastName: customer?.lastName,
      phone: customer?.phone
    },
  });
}

// Mapper Functions

type OrderLineItem = {
  sku: string;
  name: string;
  quantity: number;
  brand: string;
  categoryNames?: string[];
  discountAmount: number;
  couponAmount: number;
  listPrice: number;
  salePrice: number;
  retailPrice: number;
  options?: Array<{
    name: string;
    value: string;
  }>;
};

type OrderLineItemProps = { lineItem?: OrderLineItem, product?: Product };
const customOrderLineItem = ({ lineItem, product }: OrderLineItemProps) => {
  if (!lineItem) {
    return {};
  }

  const variant = product?.variants?.find(v => lineItem.sku === v.sku);
  const gpm = variant?.productMetafields?.find(m => m.key === 'gpm')?.value?.toString() ?? product?.productMetafields?.find(m => m.key === 'gpm')?.value?.toString();
  const selectedOptions = lineItem.options?.map(({ name, value }) => `${name}: ${value}`)?.join(' ');

  return {
    id: lineItem.sku,
    name: lineItem.name,
    selected_options: selectedOptions,
    brand: lineItem.brand,
    category: lineItem.categoryNames?.at(0),
    price: lineItem.salePrice?.toFixed(2) ?? lineItem.listPrice.toFixed(2),
    retail: lineItem.retailPrice?.toFixed(2),
    discount: lineItem.discountAmount.toFixed(2),
    gpm: stringToFixed(gpm),
    quantity: lineItem.quantity
  };
};

// Utility Functions

const stringToFixed = (money?: string) => {
  return tryParseMoney(money)?.toFixed(2)
};

const tryParseMoney = (money?: string) => {
  try {
    return money ? parseFloat(money) : undefined;
  }
  catch { }
};

export function getDevice() {
  if (deviceDetect.isAndroid) return 'Android'
  if (deviceDetect.isWinPhone) return 'Windows Phone'
  if (deviceDetect.isMobileSafari) return 'Safari'
  if (deviceDetect.isIOS) return 'iOS'
  if (deviceDetect.isChrome) return 'Chrome'
  if (deviceDetect.isFirefox) return 'Firefox browser'
  if (deviceDetect.isSafari) return 'Safari browser'
  if (deviceDetect.isOpera) return 'Opera browser'
  if (deviceDetect.isIE) return 'Internet Explorer browser'
  if (deviceDetect.isEdge) return 'Edge browser'
  if (deviceDetect.isChromium) return 'Chromium'
  if (deviceDetect.isSmartTV) return 'SmartTv'
}
