'use client'

import useUser from 'components/hooks/use-user';
import { getCookie } from 'cookies-next';
import { usePathname, useSearchParams } from 'next/navigation';
import React, { MutableRefObject, useEffect } from 'react';
import { FunnelData } from 'shared-react-components';
import * as customAnalytics from '../analytics/custom';

const prevPathname = React.createRef<string | undefined>() as MutableRefObject<string | undefined>;
const prevSearchParams = React.createRef<string | undefined>() as MutableRefObject<string | undefined>;

const prevPushPathname = React.createRef<string | undefined>() as MutableRefObject<string | undefined>;
const prevPushSearchParams = React.createRef<string | undefined>() as MutableRefObject<string | undefined>;

export default function useCustomAnalytics({ funnelData, onReady }: { funnelData?: FunnelData, onReady?: () => void }) {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const { user, loading: userLoading } = useUser();
  const ready = !userLoading;

  useEffect(() => {
    if (ready) {
      const sessionId = getCookie('sessionId')?.toString();
      setTimeout(() => {
        const pageEnterProps = {
          customer: user,
          sessionId,
          funnelData,
          prevPathname: prevPathname.current ?? '',
          prevSearchParams: prevSearchParams.current ?? '',
          pathname: pathname ? pathname : '',
          searchParams: searchParams ? searchParams.toString() : '',
        };
        customAnalytics.pageEnter(pageEnterProps);
  
        onReady && onReady();

        prevPathname.current = pathname ? pathname : undefined;
        prevSearchParams.current = searchParams?.toString();
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, ready])

  useEffect(() => {
    const pageLeave = () => {
      const sessionId = getCookie('sessionId')?.toString();
      const pathname = window.location.pathname;
      const searchParams = new URLSearchParams(window.location.search);
      const pageLeaveProps = {
        customer: user,
        sessionId,
        funnelData,
        prevPathname: prevPushPathname.current ?? '',
        prevSearchParams: prevPushSearchParams.current ?? '',
        pathname: pathname ? pathname : '',
        searchParams: searchParams ? searchParams.toString() : '',
      };
      customAnalytics.pageLeave(pageLeaveProps);

      prevPushPathname.current = pathname ? pathname : undefined;
      prevPushSearchParams.current = searchParams?.toString();
    };
    
    const handleBrowseAway = (_event: BeforeUnloadEvent) => {
      pageLeave();
    }

    const handlePageNavigation = (_event: Event) => {
      pageLeave();
    }

    window.addEventListener('beforeunload', handleBrowseAway)
    window.addEventListener('pushstate', handlePageNavigation)
    return () => {
      window.removeEventListener('beforeunload', handleBrowseAway)
      window.removeEventListener('pushstate', handlePageNavigation)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}
