'use client'

import useUser from 'components/hooks/use-user';
import { getCookie, setCookie } from 'cookies-next';
import { Cart } from 'lib/bigcommerce/types';
import { Customer } from 'lib/bigcommerce/types/customer';
import { usePathname, useSearchParams } from 'next/navigation';
import React, { MutableRefObject, useEffect } from 'react';
import { FunnelData } from 'shared-react-components';
import { v4 as uuid4 } from 'uuid';
import * as gtm from '../analytics/gtm';
import useCart from './use-cart';

const prevPathname = React.createRef<string | undefined>() as MutableRefObject<string | undefined>;
const prevSearchParams = React.createRef<string | undefined>() as MutableRefObject<string | undefined>;

export default function useGtm({ funnelData, onReady }: { funnelData?: FunnelData, onReady?: (user?: Customer, cart?: Cart) => void }) {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const { user, loading: userLoading } = useUser();
  const { cart, loading: cartLoading } = useCart();
  const ready = !userLoading && !cartLoading;

  useEffect(() => {
    if (ready) {
      let sessionId: string;
      const sessionIdCookie = getCookie('sessionId');
      if (sessionIdCookie) {
        sessionId = sessionIdCookie.toString();
      } else {
        sessionId = uuid4();
        setCookie('sessionId', sessionId);
      }
      setTimeout(() => {
        gtm.baseGtmData({
          customer: user,
          sessionId
        });
  
        gtm.pageView({
          prevPathname: prevPathname.current ?? '',
          prevSearchParams: prevSearchParams.current ?? '',
          pathname: pathname ? pathname : '',
          searchParams: searchParams ? searchParams.toString() : '',
        });
  
        onReady && onReady(user, cart);

        prevPathname.current = pathname ? pathname : undefined;
        prevSearchParams.current = searchParams?.toString();
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, ready])

  return null;
}
